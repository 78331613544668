import service from '@/until/api'

/**
 * @msg: 获取列表
 * @param {*} data
 */
export function _GetLists(data) {
  return service({
    url: '/protocol-nacos/carAlarm/getWarningProtocol',
    method: 'get',
    params: data
  })
}

/**
 * @msg: 添加协议
 * @param {*} data
 */
export function _AddWarningProtocol(data) {
  return service({
    url: '/protocol-nacos/carAlarm/addWarningProtocol',
    method: 'post',
    data
  })
}

/**
 * @msg: 编辑协议
 * @param {*} data
 */
export function _EditWarningProtocol(data) {
  return service({
    url: '/protocol-nacos/carAlarm/editWarningProtocol',
    method: 'post',
    data
  })
}

/**
 * @msg: 校验报警事件字段唯一
 * @param {*} data
 */
export function _VerifyWarningItem(data) {
  return service({
    url: '/protocol-nacos/carAlarm/judgeWarningItem',
    method: 'get',
    params: data
  })
}

/**
 * @msg: 同一个报警事件，协议字段值不可重复
 * @param {*} data
 */
export function _VerifyProtocol(data) {
  return service({
    url: '/protocol-nacos/carAlarm/judgeProtocolFieldValue',
    method: 'get',
    params: data
  })
}

/**
 * @msg: 删除协议
 * @param {*} data
 */
export function _DeleteProtocol(data) {
  return service({
    url: '/protocol-nacos/carAlarm/deleteWarningProtocol',
    method: 'post',
    data
  })
}

/**
 * @msg: 模板下载
 */
export function _FileDownload() {
  return service({
    url: '/file-nacos/excelDownload/exportTemplate/协议字段导入模板.xlsx',
    responseType: 'blob',
    method: 'get'
  })
}

/**
 * @msg: 上传文件
 * @param {*}
 */
export function _UploadFile(data) {
  return service({
    url: '/file-nacos/excelUpload/batchWarningProtocol',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}
