<template>
  <div>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="报警管理" name="alarm">
        <!-- 搜索按钮 -->
        <el-form inline>
          <el-form-item>
            <el-select
              placeholder="请选择车辆类型"
              class="centerSelInput left"
              v-model="alarmSearch.vehicleType"
            >
              <el-option
                :label="item.typeName"
                :value="item.id"
                v-for="item in carType"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              placeholder="报警等级"
              class="centerSelInput left"
              v-model="alarmSearch.warningLevel"
            >
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="item in warningLevel"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <button class="centerCzbtn" type="button" @click="searchBtn">
              搜索
            </button>
          </el-form-item>
          <el-form-item>
            <button class="centerBtn resetbtn" type="button" @click="resetBtn">
              重置
            </button>
          </el-form-item>
          <el-form-item>
            <button class="centerBtn" type="button" @click="addAlarm = true">
              添加字段
            </button>
          </el-form-item>
          <el-form-item>
            <button class="centerBtn" type="button" @click="importAlarm = true">
              导入字段
            </button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <!-- 表格信息 -->
    <Table
      :data1="tableData"
      :columns1="alarmColumns"
      :total="total"
      @getAjax="getTableData"
      :loading="loading"
      :page="alarmSearch.Page"
      :size="alarmSearch.Size"
    />
    <!-- 新增抽屉 -->
    <el-drawer
      :title="isAlarmAdd ? '添加字段' : '编辑字段'"
      :visible.sync="addAlarm"
      destroy-on-close
      @closed="addAlarmClosed"
    >
      <div class="drawer__content">
        <el-form
          ref="addForm"
          label-width="100px"
          :model="addAlarmForm"
          :rules="addAlarmRules"
        >
          <el-form-item label="车辆类型" prop="vehicleType">
            <el-select
              v-model="addAlarmForm.vehicleType"
              placeholder="请选择车辆类型"
            >
              <el-option
                :label="item.typeName"
                :value="item.id"
                v-for="item in carType"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报警ID" prop="warningName">
            <el-input v-model="addAlarmForm.warningName"></el-input>
          </el-form-item>
          <el-form-item label="报警事件" prop="warningItem">
            <el-input v-model="addAlarmForm.warningItem"></el-input>
          </el-form-item>
          <el-form-item label="协议字段值" prop="protocolFieldValue">
            <el-input v-model="addAlarmForm.protocolFieldValue"></el-input>
          </el-form-item>
          <el-form-item label="报警控制器" prop="vehicleWarningController">
            <el-select v-model="addAlarmForm.vehicleWarningController">
              <el-option
                v-for="item in warningControllerDict"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报警等级" prop="warningLevel">
            <el-radio-group v-model="addAlarmForm.warningLevel">
              <el-radio
                :label="item.value"
                v-for="item in warningLevel"
                :key="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div class="drawer__footer">
        <el-button
          type="primary"
          v-if="isAlarmAdd"
          @click="submitAlarm"
          :loading="addAlarmLoading"
          >确定</el-button
        >
        <el-button
          type="primary"
          v-else
          @click="editAlarm"
          :loading="addAlarmLoading"
          >确定</el-button
        >
        <el-button @click="addAlarm = false">取 消</el-button>
      </div>
    </el-drawer>
    <!-- 导入抽屉 -->
    <el-drawer
      title="导入字段"
      :visible.sync="importAlarm"
      destroy-on-close
      @closed="alarmUploadClosed"
    >
      <div class="drawer__content">
        <el-upload
          class="upload-demo"
          drag
          action="#"
          :limit="1"
          :file-list="alarmFileList"
          :on-change="alarmHandleUpXls"
          :on-remove="alarmRemoveXls"
          :auto-upload="false"
        >
          <!-- :on-change="alarmHandleUpXls" -->
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            <span>注：请参照模板</span>
            <span @click="templateGet">下载模板</span>
          </div>
        </el-upload>
        <div class="uploadBtn">
          <el-button type="primary" @click="alarmUpload">上传</el-button>
        </div>
      </div>
      <div class="drawer__footer">
        <el-button @click="importAlarm = false">取 消</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import {
  _GetLists,
  _AddWarningProtocol,
  _EditWarningProtocol,
  _VerifyWarningItem,
  _VerifyProtocol,
  _DeleteProtocol,
  _FileDownload,
  _UploadFile
} from '@/api/systemManagement/protocolManage/index.js'
import { _CarType } from '@/api/sass'
import { saveAs } from 'file-saver'
import { warningControllerDict } from '@/until/dictionaries.js'
export default {
  components: {
    Table //Table组件
  },
  data() {
    // 校验报警事件
    const verifyWarningItem = (rule, value, callback) => {
      let http = async () => {
        try {
          let { data } = await _VerifyWarningItem({
            warningItemUnique: value,
            vehicleType: this.addAlarmForm.vehicleType
          })
          let isUnique = data.isUnique
          if (isUnique == 0) {
            callback()
          } else {
            callback(new Error('同一车辆类型，报警事件不可重复'))
          }
        } catch (error) {
          callback(new Error('校验失败，网络错误!'))
        }
      }
      // 判断是添加还是编辑
      if (this.isAlarmAdd) {
        http()
      } else {
        // 是编辑判断是否和自己一样
        if (
          value == this.editAlarmFormCopy.warningItem &&
          this.addAlarmForm.vehicleType == this.editAlarmFormCopy.vehicleType
        ) {
          callback()
        } else {
          http()
        }
      }
    }
    // 校验协议字段值
    const verifyProtocol = (rule, value, callback) => {
      let http = async () => {
        try {
          let { data } = await _VerifyProtocol({
            warningName: this.addAlarmForm.warningName,
            protocolFieldValueUnique: value
          })
          let isUnique = data.isUnique
          if (isUnique == 0) {
            callback()
          } else {
            callback(new Error('同一个报警ID，协议字段值不可重复'))
          }
        } catch (error) {
          callback(new Error('校验失败，网络错误!'))
        }
      }
      if (this.isAlarmAdd) {
        http()
      } else {
        // 是编辑判断是否和自己一样
        if (
          this.addAlarmForm.warningName == this.editAlarmFormCopy.warningName &&
          value == this.editAlarmFormCopy.protocolFieldValue
        ) {
          callback()
        } else {
          http()
        }
      }
    }
    return {
      carType: [],
      warningControllerDict: warningControllerDict,
      activeName: 'alarm',
      alarmSearch: {
        Page: 1, // 页码
        Size: 10, // 大小
        warningLevel: null, // 报警等级
        vehicleType: null // 车辆类型
      },
      tableData: [], // 表格数据
      total: 0,
      loading: false,
      addAlarm: false,
      importAlarm: false,
      isAlarmAdd: true,
      addAlarmLoading: false,
      addAlarmForm: {
        vehicleType: null, // 车辆类型
        warningRuleDataId: null, // id
        warningName: null, // 报警ID
        warningItem: null, // 报警事件
        protocolFieldValue: null, // 协议字段值
        warningLevel: null, // 报警等级
        vehicleWarningController: null // 报警控制器
      },
      alarmFileList: [],
      editAlarmFormCopy: {}, // 编辑时排除自己校验
      warningLevel: [
        {
          value: 1,
          label: '一级报警'
        },
        {
          value: 2,
          label: '二级报警'
        },
        {
          value: 3,
          label: '三级报警'
        }
      ],
      addAlarmRules: {
        vehicleType: [
          { required: true, message: '请选择车辆类型', trigger: 'change' }
        ],
        warningName: [
          { required: true, message: '请输入报警ID', trigger: 'blur' }
        ],
        warningItem: [
          { required: true, message: '请输入报警事件', trigger: 'blur' },
          { validator: verifyWarningItem, trigger: 'blur' }
        ],
        protocolFieldValue: [
          { required: true, message: '请输入协议字段值', trigger: 'blur' },
          { validator: verifyProtocol, trigger: 'blur' }
        ],
        warningLevel: [
          { required: true, message: '请选择报警等级', trigger: 'change' }
        ],
        vehicleWarningController: [
          { required: true, message: '请选择报警控制器', trigger: 'change' }
        ]
      },
      alarmColumns: [
        {
          label: '车辆类型',
          prop: 'vehicleType',
          render: (h, params) => {
            let label = '--'
            for (let i = 0; i < this.carType.length; i++) {
              if (this.carType[i].id == params.row.vehicleType) {
                label = this.carType[i].typeName
                break
              }
            }
            return h('span', label)
          }
        },
        {
          label: '报警ID',
          prop: 'warningName'
        },
        {
          label: '报警事件',
          prop: 'warningItem'
        },
        {
          label: '协议字段值',
          prop: 'protocolFieldValue'
        },
        {
          label: '报警等级',
          render: (h, params) => {
            let label = '-'
            for (let i = 0; i < this.warningLevel.length; i++) {
              if (this.warningLevel[i].value == params.row.warningLevel) {
                label = this.warningLevel[i].label
                break
              }
            }
            return h('span', label)
          }
        },
        {
          label: '报警控制器',
          prop: 'vehicleWarningController'
        },
        {
          label: '添加人',
          prop: 'createUserName'
        },
        {
          label: '操作',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'operationA',
                  on: {
                    click: () => {
                      this.isAlarmAdd = false
                      this.addAlarm = true
                      this.addAlarmForm.warningRuleDataId =
                        params.row.warningRuleDataId
                      this.addAlarmForm.warningName = params.row.warningName
                      this.addAlarmForm.warningItem = params.row.warningItem
                      this.addAlarmForm.protocolFieldValue =
                        params.row.protocolFieldValue
                      this.addAlarmForm.warningLevel = params.row.warningLevel
                      this.addAlarmForm.vehicleWarningController =
                        params.row.vehicleWarningController
                      this.addAlarmForm.vehicleType = params.row.vehicleType
                      this.editAlarmFormCopy = JSON.parse(
                        JSON.stringify(this.addAlarmForm)
                      )
                    }
                  }
                },
                '编辑'
              ),
              h(
                'span',
                {
                  class: 'operationA ml20',
                  on: {
                    click: () => {
                      this.$confirm('是否删除该条协议字段?', '删除', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消'
                      })
                        .then(async () => {
                          try {
                            let { success } = await _DeleteProtocol({
                              warningRuleDataId: params.row.warningRuleDataId
                            })
                            if (success) {
                              this.$message.success('删除成功')
                              this.getTableData()
                            } else {
                              this.$message.error('删除失败')
                            }
                          } catch (error) {
                            this.$message.error('网络错误')
                          }
                        })
                        .catch(() => {})
                    }
                  }
                },
                '删除'
              )
            ])
          }
        }
      ]
    }
  },
  mounted() {
    this.getTableData()
    this.getCarType()
  },
  methods: {
    // 获取列表
    async getTableData(pagination) {
      this.loading = true
      if (pagination) {
        if (pagination.page) {
          this.alarmSearch.Page = pagination.page
          this.alarmSearch.Size = pagination.limit
        }
      }
      try {
        let { data } = await _GetLists(this.alarmSearch)
        this.total = data.total
        this.tableData = data.list
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    // 搜索
    searchBtn() {
      this.alarmSearch.Page = 1
      this.getTableData()
    },
    // 重置
    resetBtn() {
      Object.assign(this.$data.alarmSearch, this.$options.data().alarmSearch)
      this.alarmSearch.Page = 1
      this.getTableData()
    },
    // 新增
    submitAlarm() {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          this.addAlarmLoading = true
          try {
            let { success } = await _AddWarningProtocol(this.addAlarmForm)
            if (success) {
              this.$message.success('添加成功')
              this.addAlarm = false
              this.getTableData()
              this.addAlarm = false
            } else {
              this.$message.error('添加失败')
            }
            this.addAlarmLoading = false
          } catch (error) {
            this.addAlarmLoading = false
          }
        }
      })
    },
    // 编辑
    editAlarm() {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          this.addAlarmLoading = true
          try {
            let { success } = await _EditWarningProtocol(this.addAlarmForm)
            if (success) {
              this.$message.success('编辑成功')
              this.addAlarm = false
              this.getTableData()
            } else {
              this.$message.error('编辑失败')
            }
            this.addAlarmLoading = false
          } catch (error) {
            this.addAlarmLoading = false
          }
        }
      })
    },
    // 关闭弹窗
    addAlarmClosed() {
      this.isAlarmAdd = true
      Object.assign(this.$data.addAlarmForm, this.$options.data().addAlarmForm)
      Object.assign(
        this.$data.editAlarmFormCopy,
        this.$options.data().editAlarmFormCopy
      )
    },
    // 获取文件模板
    async templateGet() {
      let res = await _FileDownload()
      let blob = new Blob([res])
      saveAs(blob, '协议管理导入模板.xlsx')
    },
    // 限制上传的文件类型
    alarmHandleUpXls(file, fileList) {
      let whiteList = ['xlsx']
      let fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是xlsx格式')
        this.alarmFileList = []
      } else {
        this.alarmFileList = fileList
      }
    },
    // 移除文件
    alarmRemoveXls(file, fileList) {
      this.alarmFileList = fileList
    },
    // 上传
    async alarmUpload() {
      if (!this.alarmFileList.length) {
        this.$message.error('没有上传文件')
      } else {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let formData = new FormData()
        formData.append('file', this.alarmFileList[0].raw)
        try {
          let { success, message } = await _UploadFile(formData)
          if (success) {
            loading.close()
            this.$message.success('上传成功')
            this.importAlarm = false
            this.alarmSearch.Page = 1
            this.alarmSearch.warningLevel = null
            this.getTableData()
          } else {
            loading.close()
            this.$message.error(message)
          }
        } catch (error) {
          loading.close()
        }
      }
    },
    // 关闭上传弹窗
    alarmUploadClosed() {
      this.alarmFileList = []
    },
    // 获取车辆类型
    async getCarType() {
      let { success, data, message } = await _CarType()
      if (success) {
        this.carType = data.list
      } else {
        this.$message.error(message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-tabs__content {
  overflow: visible;
}
/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: 0px solid #e4e7ed;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0px solid #e4e7ed;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  //border-bottom-color: #f5f8fa;
  border-bottom: 0px solid #e4e7ed;
  border-left: 0px solid #e4e7ed;
  border-right: 0px solid #e4e7ed;
}
/deep/.el-drawer__body {
  display: flex;
  flex-direction: column;
  .drawer__content {
    flex: 1;
    .upload-demo {
      display: flex;
      flex-direction: column;
      align-items: center;
      .el-upload__tip {
        margin-top: 10px;
        width: 80%;
        display: flex;
        justify-content: space-between;
        :last-child {
          color: #409eff;
          cursor: pointer;
        }
      }
    }
    .uploadBtn {
      margin-top: 20px;
      text-align: center;
    }
  }
  .drawer__footer {
    text-align: right;
    margin-bottom: 20px;
  }
  .el-select {
    width: 100%;
  }
}
</style>
